@import './../../../../scss/theme-bootstrap';

.footer-social-media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  &__menu {
    display: flex;
  }
  &__link {
    margin: 2px 10px;
    .icon {
      font-size: 28px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: none;
    }
  }
}
